import React from "react";
import "../../Common.css";
import {
  Modal,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Typography,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

const FilterPopup = ({
  open,
  handleClose,
  selectedCategory,
  selectedTechnology,
  selectedComponentName,
  handleCategoryChange,
  handleTechnologyChange,
  handleComponentNameChange,
  handleToggle,
  selectedToggle,
  uniqueCategories,
  uniqueTechnologies,
  uniqueComponentNames,
}) => {
  const modalStyle = {
    position: "absolute",
    top: "59%",
    left: "88%",
    transform: "translate(-50%, -50%)",
    width: "260px", // Adjust the width as needed
    backgroundColor: "white",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={modalStyle}>
        <Paper elevation={3} className="filterPopupPaper">
          <Typography
            variant="h6"
            gutterBottom
            className="filterPopupTypography"
          >
            Filters
          </Typography>
          <Divider />
          <Typography
            variant="subtitle1"
            gutterBottom
            className="filterPopupLine"
          >
            Basic Information
          </Typography>
          <FormControl
            fullWidth
            className="filterPopupCategory"
          >
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <MenuItem value="">All</MenuItem>
              {uniqueCategories &&
                uniqueCategories.length > 0 &&
                uniqueCategories.map((category, index) => (
                  <MenuItem key={index} value={category}>
                    {category}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth className="formcontrolFilterPopup">
            <InputLabel id="technology-select-label">Technology</InputLabel>
            <Select
              labelId="technology-select-label"
              id="technology-select"
              value={selectedTechnology}
              onChange={handleTechnologyChange}
            >
              <MenuItem value="">All</MenuItem>
              {uniqueTechnologies &&
                uniqueTechnologies.length > 0 &&
                uniqueTechnologies.map((technology, index) => (
                  <MenuItem key={index} value={technology}>
                    {technology}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="component-name-label">Component Name</InputLabel>
            <Select
              labelId="component-name-label"
              id="component-name-select"
              value={selectedComponentName}
              onChange={handleComponentNameChange}
            >
              <MenuItem value="">All</MenuItem>
              {uniqueComponentNames &&
                uniqueComponentNames.length > 0 &&
                uniqueComponentNames.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="filterPopupLine2"
          >
            Restrictions
          </Typography>
          <Divider className="dividerFilterpopup" />

          {/* Toggle buttons */}
          <ToggleButtonGroup
            value={selectedToggle}
            onChange={handleToggle}
            aria-label="toggle button group"
          >
            <ToggleButton className="toggleButtonFilterpoup" value="View All">
              View All 
            </ToggleButton>
            <ToggleButton
              className="toggleButtonFilterpoup"
              value="View Recent"
            >
              View Recent
            </ToggleButton>
          </ToggleButtonGroup>
        </Paper>
      </div>
    </Modal>
  );
};

export default FilterPopup;
