import React,{useState} from 'react'
import Icon from "../../Images/CheckCircleFilled.png";
import Icon2 from "../../Images/CancelFilled.png";
import { Button } from "@mui/material";
import InProgress from './SubmitPages/InProgress';

function ActionsPopUp({selectedComponentState,filteredTableData,tableData,itemValue,itemId,styli}){
    const [isOpen, setIsOpen] = useState(false);
    // const [selectedComponentState, setSelectedComponentState] = useState("");
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [openMyComponentsDialog, setOpenMyComponentsDialog] = useState(false);
    const [openLinksDialog, setOpenLinksDialog] = useState(false);
    const [openDropdownRow, setOpenDropdownRow] = useState(null);
    const [tableDataa, setTableData] = useState(tableData)
    
    const handleOpenMyComponentsDialog = (rowData) => {
        // console.log("Itemval rowdata is : ",rowData)
        setSelectedRowData(rowData);
        if (rowData.ComponentState === "In Progress") {
            setOpenMyComponentsDialog(true);
        } else if (rowData.ComponentState === "Ready to Publish") {
            setOpenLinksDialog(true);
        }
        setOpenDropdownRow(null)
    };

    const handleRemoveRow = (rowId) => {
        console.log("Remove is clicked !!", rowId)
        // Fetch the data of the deleted row
        console.log("Data is ",filteredTableData)
        const deletedRow = filteredTableData.find((item) => item._id === rowId);
        // Send the deleted row data to the API
        fetch("https://prod.appstudioltts.com/api/request/community-request", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ComponentName: deletedRow.ComponentName,
                ComponentDetails: deletedRow.ComponentDetails,
                Category: deletedRow.Category,
                Technology: deletedRow.Technology,
                FlagValue: deletedRow.FlagValue
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Deleted row data sent successfully:", data);
            })
            .catch((error) => {
                console.error("Error sending deleted row data:", error);
            });

        // Remove the row from the table
        fetch(
            `https://prod.appstudioltts.com/api/linkstateupdate/removedata/${rowId}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => response.json())
            .then(() => {
                const updatedTableData = tableDataa.filter((item) => item._id !== rowId);
                setTableData(updatedTableData);
            })
            .catch((error) => {
                console.error("Error removing row:", error);
            });
        setOpenDropdownRow(null)
        
    };

   
    return(
        <>
        <div className="dropDowndiv" style={styli} onBlur={() => setIsOpen(false)} tabIndex="0" autoFocus>
                            <Button
                                variant="contained"
                                onClick={() => { handleOpenMyComponentsDialog(itemValue) }}
                                // onClick={()=>{alert("Hiii")}}
                                style={{
                                    fontSize: "12px",
                                    padding: "9px",
                                    width: "99px",
                                    borderRadius: 0,
                                    backgroundColor:
                                        selectedComponentState === "Ready to Review" ||
                                            selectedComponentState === "Rejected"
                                            ? "#e0e0e0"
                                            : "white",
                                    color:
                                        selectedComponentState === "Ready to Review" ||
                                            selectedComponentState === "Rejected"
                                            ? "#bababa"
                                            : "black",

                                    transition: "background-color 0.3s",
                                }}
                                onMouseOver={(e) => {
                                    if (
                                        !(
                                            selectedComponentState ===
                                            "Ready to Review" ||
                                            selectedComponentState === "Rejected"
                                        )
                                    ) {
                                        e.currentTarget.style.backgroundColor =
                                            "lightblue";
                                    }
                                }}
                                onMouseOut={(e) => {
                                    if (
                                        !(
                                            selectedComponentState ===
                                            "Ready to Review" ||
                                            selectedComponentState === "Rejected"
                                        )
                                    ) {
                                        e.currentTarget.style.backgroundColor = "white";
                                    }
                                }}
                                disabled={
                                    selectedComponentState === "Ready to Review" ||
                                    selectedComponentState === "Rejected"
                                } // Disable if component state is 'Ready to Review'
                            >
                                <img
                                    src={Icon}
                                    alt="Submit"
                                    style={{
                                        marginRight: "10px",
                                        opacity:
                                            selectedComponentState ===
                                                "Ready to Review" ||
                                                selectedComponentState === "Rejected"
                                                ? 0.5
                                                : 1,
                                    }}
                                />
                                Submit
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => { handleRemoveRow(itemValue._id)}}
                                // onClick={()=>{alert("Hellooo")}}

                                className="RemoveButtonDropdown"
                                onMouseOver={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                    "lightblue")
                                }
                                onMouseOut={(e) =>
                                    (e.currentTarget.style.backgroundColor = "white")
                                }
                            >
                                <img
                                    src={Icon2}
                                    alt="Remove"
                                    className="removeIconContribute"
                                />
                                Remove
                            </Button>
                        </div>

                        {openMyComponentsDialog&& <InProgress MyComponentsDialog={openMyComponentsDialog}/> }           
        </>
    )
}
export default ActionsPopUp;




/* Committed Code in MyComponents code */



// import React, { useEffect, useState, useRef } from 'react';
// import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Typography from '@mui/material/Typography';
// import { Button, Grid } from "@mui/material";
// import { useMsal } from "@azure/msal-react";
// import AddIcon from '@mui/icons-material/Add';
// import { NavLink } from 'react-router-dom';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import ActionsDrop from './ActionsPopup'
// import AddComponent from '../Global/AddComponent';

// const columns = [
//     { id: 'no', label: 'Sr No.' },
//     { id: 'ComponentName', label: 'Component Name' },
//     { id: 'ComponentDetails', label: 'Component Details' },
//     { id: 'Category', label: 'Category' },
//     { id: 'Technology', label: 'Technology' },
//     { id: 'ComponentState', label: 'ComponentState' },
//     { id: 'actions', label: 'Actions' }
// ];



// export default function StickyHeadTable() {
//     const currentTab = 1;
//     const { instance } = useMsal();
//     const [page, setPage] = React.useState(0);
//     const [rowsPerPage, setRowsPerPage] = React.useState(10);
//     const [filteredTableData, setFilteredTableData] = useState([]);
//     const [myFilterTableData, setMyFilterTableData] = useState([]);
//     const [tableData, setTableData] = useState([]);
//     const [selectedRows, setSelectedRows] = useState([]);
//     const [ComponentPopup, setComponentpopup] = useState(false);
//     const [openFilterPopup, setOpenFilterPopup] = useState(false);
//     const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 })
//     const [isOpen, setIsOpen] = useState(false);
//     const [itemVal, setItemVal] = useState([])
//     const [openDropdownRow, setOpenDropdownRow] = useState(null);
//     const [selectedComponentState, setSelectedComponentState] = useState("");
//     const dropdownRef = useRef(null);
//     const dialogRef = useRef(null);


//     const styli = {
//         position: 'absolute',
//         top: `${dropdownPosition.top + 5}px`,
//         left: `${dropdownPosition.left}px`

//     }


//     const TableDataFetch = async () => {
//         // setLoading(true)
//         setFilteredTableData([])
//         try {
//             const currentUserEmail = instance.getAllAccounts()?.[0]?.username;


//                 fetch("https://prod.appstudioltts.com/api/request/")
//                 .then((response) => response.json())
//                 .then((data) => {
//                     // setTableData(data.filter((i) => i.FlagValue == 1));
//                     // setFilteredTableData(data.filter((i) => i.FlagValue == 0))
//                     setFilteredTableData(data.filter((i) => i.FlagValue == 1))
//                 })
//                 .catch((error) => {
//                     console.error("Error fetching data:", error);
//                 });

//                 fetch("https://prod.appstudioltts.com/api/linkstateupdate/fetchdata")
//                 .then((response) => response.json())
//                 .then((data) => {
//                     const myComponentsData = data.filter(
//                         (item) => item.Email === currentUserEmail
//                     );
//                     setTableData(myComponentsData);
//                     setFilteredTableData(myComponentsData);
//                 })
//                 .catch((error) => {
//                     console.error("Error fetching data for My Components: ", error);
//                 });

//             const selectedIds = filteredTableData.map((selectedRow) => selectedRow._id);


//             //  Promise.all(
//             selectedIds.map((selectedId) => {
//                 return fetch(
//                     `https://prod.appstudioltts.com/api/request/${selectedId}`,
//                     {
//                         method: "DELETE",
//                         headers: {
//                             "Content-Type": "application/json",
//                         },
//                     }
//                 )
//                     .then((response) => response.json())
//                     .catch((error) => {
//                         console.error("Error deleting data:", error);
//                         return null;
//                     });
//             })
//             //  )
//             //      .then(() => {
//             //          //Refetch the data to update the table after deletion
//             //          fetch("https://prod.appstudioltts.com/api/request/")
//             //              .then((response) => response.json())
//             //              .then((data) => {
//             //                  setTableData(data);
//             //                  setFilteredTableData(data);
//             //              })
//             //              .catch((error) => {
//             //                  console.error("Error fetching data", error);
//             //              });
//             //      })
//             Promise.all(
//                 filteredTableData.map((selectedRow) => {
//                     console.log("Rowdata of mycompo : ", selectedRow)
//                     const rowData = {
//                         ComponentName: selectedRow["ComponentName"],
//                         ComponentDetails: selectedRow["ComponentDetails"],
//                         Category: selectedRow["Category"],
//                         Technology: selectedRow["Technology"],
//                         SenderName: selectedRow["SenderName"],
//                         Email: selectedRow["SenderEmail"],
//                         ComponentState: "In Progress",
//                     };

//                     return fetch(
//                         "https://prod.appstudioltts.com/api/linkstateupdate/submitdata",
//                         {
//                             method: "POST",
//                             headers: {
//                                 "Content-Type": "application/json",
//                             },
//                             body: JSON.stringify(rowData),
//                         }
//                     )
//                         .then((response) => response.json())
//                         .catch((error) => {
//                             console.error("Error sending data:", error);
//                             return null;
//                         });
//                 })
//             )
//                 .then(() => {
//                     // setFilteredTableData([])

//                     fetch("https://prod.appstudioltts.com/api/linkstateupdate/fetchdata")
//                         .then((response) => response.json())
//                         .then((data) => {
//                             const myComponentsData = data.filter(
//                                 (item) => item.Email === currentUserEmail
//                             );
//                             setTableData(myComponentsData);
//                             setFilteredTableData(myComponentsData);
//                         })
//                         .catch((error) => {
//                             console.error("Error fetching data for My Components: ", error);
//                         });
//                 })

//         }
//         catch (error) {
//             console.log("Error occured while fetching", error)
//         }
//         // setLoading(false)
//     }

//     useEffect(() => {
//         TableDataFetch()
//     }, [])

//     // const handleClickOutside = (event) => {
//     //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//     //       setIsOpen(false);
//     //     }
//     //   };

//     const handleClickOutside = (event) => { 
//         console.log("dialog ref", dialogRef.current,dialogRef.current.contains(event.target))
//         console.log("dropdown ref and event",event.target, dropdownRef.current.contains(event.target))
//         if ( 
//           dropdownRef.current && 
//           !dropdownRef.current.contains(event.target) && 
//           !dialogRef.current.contains(event.target) // Exclude the dialog from closing 
//         ) { 
//           setIsOpen(false); 
//         } 
//       }; 

//       useEffect(() => {
//         document.addEventListener("mousedown", handleClickOutside);
//         return () => {
//           document.removeEventListener("mousedown", handleClickOutside);
//         };
//       }, [document.addEventListener,document.removeEventListener]);


//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(event.target.value);
//         setPage(0);
//     };

//     const handleOpenFilterPopup = () => {
//         setOpenFilterPopup(true);
//     };


//     // Movert Icon Open or Close
//     const handleCloseDropdown = () => {
//         setOpenDropdownRow(null);
//         setIsOpen(!isOpen)
//     };

//     const handleOpenDropdown = (rowId, component, componentState) => {
//         // console.log("COmponnent is : ",component)
//         setOpenDropdownRow(rowId);
//         setItemVal(component)
//         setSelectedComponentState(componentState);
//         setIsOpen(!isOpen)
//     };

//     const toggleDropdown = () => {
//         setIsOpen(!isOpen);
//       };

//       //+Components close
//     const CloseComponentPopup = () => {
//         setComponentpopup(false);
//       };




//     return (
//         <Paper sx={{ width: '100%', overflow: 'hidden', paddingTop: '10px' }}>
//             <Grid container >
//                 <Grid item className="mybtnWrapper">
//                     <Button variant="outlined" color="primary" className="compoBtn" sx={{ fontSize: '13px' }}  onClick={() => setComponentpopup(true)}><AddIcon sx={{ width: '20px', fontSize: '15px', marginTop: '-1px' }} /> Component</Button>
//                 </Grid>
//             </Grid>
//             <TableContainer sx={{ maxHeight: 330, position: 'relative', left: "20px", width: '96%', height:'330px' }}>

//                 <Table stickyHeader aria-label="sticky table" >
//                     <TableHead>
//                         <TableRow>
//                             {columns.map((column) => (
//                                 <TableCell
//                                     key={column.id}
//                                     align={column.align}
//                                     style={{ minWidth: column.minWidth, backgroundColor: 'lightblue', color: 'black', fontWeight: 'bold' }}
//                                 >
//                                     {column.label}
//                                 </TableCell>
//                             ))}
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {filteredTableData
//                             .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                             .map((row, index) => {
//                                 // console.log("Row is :", row)
//                                 return (
//                                     <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>

//                                         {columns.map((column, i) => {
//                                             if (column.id == "no") {
//                                                 return (
//                                                     <>
//                                                         <TableCell>{index + 1}</TableCell>
//                                                     </>
//                                                 );

//                                             }
//                                             else if (column.id == "actions") {
//                                                 return (
//                                                     <>
//                                                         <TableCell>
//                                                             <MoreVertIcon
//                                                                 onClick={(e) => {
//                                                                     setDropdownPosition({ top: e.clientY, left: e.clientX })
//                                                                     openDropdownRow === index
//                                                                         ? handleCloseDropdown()
//                                                                         : handleOpenDropdown(row["_id"], row, row["ComponentState"])
//                                                                     // : {handleOpenDropdown}
//                                                                 }}
//                                                             />
//                                                         </TableCell>
//                                                     </>
//                                                 )
//                                             }

//                                             else {
//                                                 const value = row[column.id];

//                                                 return (
//                                                     <>
//                                                         <TableCell key={column.id} >
//                                                             {value}
//                                                         </TableCell>
//                                                     </>
//                                                 );
//                                             }
//                                         })}
//                                     </TableRow>
//                                 );
//                             })}
//                     </TableBody>
//                 </Table>
//             </TableContainer>
//             <TablePagination
//                 rowsPerPageOptions={[10, 25, 100]}
//                 component="div"
//                 count={filteredTableData.length}
//                 rowsPerPage={rowsPerPage}
//                 page={page}
//                 onPageChange={handleChangePage}
//                 onRowsPerPageChange={handleChangeRowsPerPage}
//                 className="paginationWrapper"
//             />
//             <Grid container justifyContent="space-between" alignItems="center">
//                 <Grid item className="headerTxt">
//                     <p></p>
//                 </Grid>
//                 <Grid item className="footerbtnWrap">
//                     <NavLink to="/home">
//                         <Button variant="outlined" color="primary" sx={{ fontSize: '13px', marginRight: '10px', padding: "6px 24px" }}> Close </Button>
//                     </NavLink>
//                 </Grid>
//             </Grid>
//             <div ref={dropdownRef}>
//                 {isOpen && <ActionsDrop selectedComponentState={selectedComponentState} styli={styli} itemValue={itemVal} itemId={openDropdownRow} filteredTableData={filteredTableData}/>}
//             </div>


//                 {/* Add Component onClick PopUp */}
//                 <div ref={dialogRef}>
//          { ComponentPopup && <AddComponent open={ComponentPopup} handleClose={CloseComponentPopup} flagVal={currentTab} />}
//           </div>
//         </Paper>
//     );
// }
