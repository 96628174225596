import logo from '../../../Images/LttsLogo.png'
import appstudiologo from '../../../Images/Appstudiopng.png'
import moment from 'moment-timezone';
import React, { useEffect, useRef,useImperativeHandle } from 'react';
import ReactToPrint from 'react-to-print';

const ComponentToPrint = React.forwardRef((props, ref) => {
    const {name,rank} = props
    console.log("Certificate Props are ",props)
      return(
        <div ref={ref}>
        {/* Your Certificate Data */}
       <div class="page">
      <div class="subpage">
        <img src={logo} height="80px"  style={{marginLeft:'40px'}}/>
        <div className="heading">Certification of Completion</div>
        <p style={{font:'caption', position: 'relative',fontSize: 'x-large', top: '11%',left: '34%'}}>This is to Certify that </p>
        <p className="heading" style={{top:'12%'}}>{name}</p>
        <p style={{fontVariant: 'normal',fontSize:' x-large', position: 'relative', top: '13%', left: '24%', width: '52%',textAlign: 'center',lineHeight:'38px'}}>Has Acheived Rank of</p>
        <p style={{fontVariant: 'normal',fontSize:' xx-large', position: 'relative', top: '13%', left: '22%', width: '52%',textAlign: 'center',lineHeight:'38px'}}>{rank}</p>
        <p style={{fontVariant: 'normal',fontSize:' x-large', position: 'relative', top: '14%', left: '20%', width: '59%',textAlign: 'center'}}>as a Contributor to </p>
        <img src={appstudiologo} className="appstudiologo"/>
        <p style={{fontVariant: 'normal',fontSize:'large', position: 'relative', top: '17%', left: '9%', width: '85%',textAlign: 'center',color:'#0077cf',lineHeight:'30px'}}>
App Studio is an in-house solution accelerator for LTTS developers. Its a web-based frontend library that allows users to consume and contribute reusable components to and from their projects.</p>
      </div>
    </div>
    </div>
      )
      });

const DownloadCertificate = ({list}) => {
    const componentRef = useRef();
    console.log("Download list is  : ",list[0])
    return (
        <div>
            <ReactToPrint
                trigger={() => <a style={{textDecoration:'none',padding:'4px',borderRadius:'4px'}}>Download Certificate</a>}
                content={() => componentRef.current}
            />
            <div style={{display: 'none'}}>
                <ComponentToPrint ref={componentRef} name={list[0].senderName} rank={list[0].rank}/>
            </div>
        </div>
    );
}

export default DownloadCertificate;


