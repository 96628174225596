import React from "react";

const Notification = ({ onClose }) => {
  return (
    <div className="notification">
      <div className="notification-content">
        <p className="pStyleNotification">
          You are successfully added as a 'Consumer'
        </p>
        <p
          className="pStyleNotification2"
        >
          As a consumer you can use App Studio components in four ways:
        </p>
        <ul className="ulStyleNotification">
          <li className="liStyleNotification">
            App Studio Libraries via npm: Seamlessly integrate components into
            your projects using App Studio Libraries available through npm.
            These pre-packaged libraries enable efficient and streamlined
            development.
          </li>
          <li className="liStyleNotification">
            Hosted URLs: Access components directly through hosted URLs
            available on the playground. Simply include the URLs in your code to
            leverage the power of App Studio components.
          </li>
          <li className="liStyleNotification">
            Code Snippets: Copy and paste code snippets provided to quickly
            incorporate specific functionalities into your applications.
          </li>
          <li className="liStyleNotification">
            Download components from Azure Artifacts, which is available in
            Azure DevOps.
          </li>

          <li
            className="linkStyleNotification"
          >
            Please click here to get yourself enrolled into MS teams App studio
            consumers group
          </li>
          <a
            href="https://teams.microsoft.com/l/team/19%3azhmNBmnjyzfJRufd0lfJDW63j5gqZetAZsQTTwEOHr41%40thread.tacv2/conversations?groupId=8043388b-9322-4c0f-a50f-5db8e58bd983&tenantId=311b3378-8e8a-4b5e-a33f-e80a3d8ba60a"
            className="hrefNotification"
            target="_blank"
            rel="noopener noreferrer"
          >
            Appstudio consumers group
          </a>
          <li
            className="linkStyleNotification"
          >
            Please click here to enroll yourself into the Azure Webapp studio
            group
          </li>
          <a
            href="https://webappstudio-react.visualstudio.com/"
            className="hrefNotification"
            target="_blank"
            rel="noopener noreferrer"
          >
            Azure webappstudio group
          </a>
        </ul>

        <button onClick={onClose}>OK</button>
      </div>
    </div>
  );
};

export default Notification;
