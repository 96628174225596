import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { TableBody, TableCell, TableRow, Table, TableHead, TableContainer, Paper} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  table: {
    // minWidth: 573px,
    width: "573px",
    marginBottom:"0px"
  },
  container: {
    // maxHeight: 600,
    // maxHeight: 421,
    width: 1000,
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs({ details, title, onClick }) {
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenu, setOpenMenu] = React.useState(false);

  const handleFilter = (rows, column, value) => {
    if (value === null) {
      return rows;
    }
    return rows.filter((row) => row[column] === value);
  };

  const getUniqueValues = (arr, key) => {
    return [...new Set(arr.map((item) => item[key]))];
  };

  const handleClick = (event, column) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelectedColumn(column);
    if (selectedColumn !== null) {
      setSelectedValue(details[0][column]);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog onClose={onClick} aria-labelledby="customized-dialog-title" open={open} >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="dialog-title">
          {title}
        </DialogTitle>
        <IconButton aria-label="close" onClick={onClick} sx={{ position: "absolute", right: 8, top: 8, color: "white" }}>
          <CloseIcon />
        </IconButton>
        <DialogContent className="dialog-box">
          <Typography>
            <TableContainer component={Paper} className={classes.container}>
              <Table stickyHeader className={classes.table} aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr No</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell style={{padding:"11px 0px"}}>
                      UserType
                      <IconButton onClick={(e) => handleClick(e, "type")} style={{padding:"8px 0px"}}>
                        {selectedColumn === "type" ? (
                          <KeyboardArrowUpIcon fontSize="small" />
                        ) : (
                          <KeyboardArrowDownIcon fontSize="small" />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                    Business Unit
                      <IconButton onClick={(e) => handleClick(e, "BU")}>
                        {selectedColumn === "BU" ? (
                          <KeyboardArrowUpIcon fontSize="small" />
                        ) : (
                          <KeyboardArrowDownIcon fontSize="small" />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                    Delivery Unit
                      <IconButton onClick={(e) => handleClick(e, "DU")}>
                        {selectedColumn === "DU" ? (
                          <KeyboardArrowUpIcon fontSize="small" />
                        ) : (
                          <KeyboardArrowDownIcon fontSize="small" />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {handleFilter(details, selectedColumn, selectedValue).map((i, index) => (
                    <TableRow key={i.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell className="text-break">{i.name}</TableCell>
                      <TableCell className="text-break">{i.id}</TableCell>
                      <TableCell>{i.type}</TableCell>
                      <TableCell>{i.BU}</TableCell>
                      <TableCell>{i.DU}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="contained">
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {openMenu && (
        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          onClick={handleClose}
        >
          {getUniqueValues(details, selectedColumn).map((option) => (
            <MenuItem
              key={option}
              selected={selectedValue === option}
              onClick={() => {
                setSelectedValue(option);
                setOpenMenu(false);
              }}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      )}
    </React.Fragment>
  );
}
















































// import React, { useState } from 'react';
// import Button from '@mui/material/Button';
// import { styled } from '@mui/material/styles';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import { TableBody, TableCell, TableRow, Table, TableHead, TableContainer, Paper } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import Typography from '@mui/material/Typography';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { makeStyles } from '@material-ui/core/styles';

// import '../../../Styling/leaderboard.css'

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2),
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1),
//   },
// }));

// const useStyles = makeStyles({
//   table: {
//     // minWidth: 573px,
//     width:'573px'
//   },
//   container: {
//     maxHeight: 600,
//     width: 1000,
//   },
// });

// export default function CustomizedDialogs({ details,title,onClick }) {
//   const [open, setOpen] = useState(true);
//   const classes = useStyles();
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedOption, setSelectedOption] = useState(null);
//   const options = ['Download Certificate', 'Place Holder', 'Place Holder'];

//   const toggling = () => setIsOpen(!isOpen);

//   const onOptionClicked = value => () => {
//     setSelectedOption(value);
//     setIsOpen(false);
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false)
//   };

//   console.log("Details are : ", details)

//   return (
//     <React.Fragment>
//       <BootstrapDialog
//         onClose={onClick}
//         aria-labelledby="customized-dialog-title"
//         open={open}
//       >
//         <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="dialog-title">
//           {title}
//         </DialogTitle>
//         <IconButton
//           aria-label="close"
//           onClick={onClick}
//           sx={{
//             position: 'absolute',
//             right: 8,
//             top: 8,
//             color: 'white',
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//         <DialogContent className='dialog-box'>
//           <Typography>
//             <TableContainer component={Paper} className={classes.container}>
//               <Table stickyHeader className={classes.table} aria-label="sticky table">
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>Sl No</TableCell>
//                     <TableCell>Name</TableCell>
//                     <TableCell>Email</TableCell>
//                     <TableCell>UserType</TableCell>
//                     <TableCell>BU</TableCell>
//                     <TableCell>DU</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 {details.map((i, index) => (
//                   <TableBody key={i.id}>
//                     <TableRow>
//                       <TableCell>{index + 1}</TableCell> 
//                       <TableCell>{i.name}</TableCell>
//                       <TableCell>{i.id}</TableCell>
//                       <TableCell>{i.type}</TableCell>
//                       <TableCell>{i.BU}</TableCell>
//                       <TableCell>{i.DU}</TableCell>
//                     </TableRow>
//                   </TableBody>
//                 ))}
//               </Table>
//             </TableContainer>
//           </Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={onClick} variant="contained">
//             Ok
//           </Button>
//         </DialogActions>
//       </BootstrapDialog>
//     </React.Fragment>
//   );
// }





























// import React, { useState } from 'react';
// import Button from '@mui/material/Button';
// import { styled } from '@mui/material/styles';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import { TableBody, TableCell, Table, TableHead, TableContainer, Paper } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import Typography from '@mui/material/Typography';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { makeStyles } from '@material-ui/core/styles';

// import '../../../Styling/leaderboard.css'

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2),
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1),
//   },
// }));


// const useStyles = makeStyles({
//   table: {
//     // minWidth: 573px,
//     width:'573px'
//   },
//   container: {
//     maxHeight: 600,
   
//   },
// });

// export default function CustomizedDialogs({ details,title,onClick }) {
//   const [open, setOpen] = useState(true);
//   const classes = useStyles();
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedOption, setSelectedOption] = useState(null);
//   const options = ['Download Certificate', 'Place Holder', 'Place Holder'];

//   const toggling = () => setIsOpen(!isOpen);

//   const onOptionClicked = value => () => {
//     setSelectedOption(value);
//     setIsOpen(false);
//   };
//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false)
//   };
//   console.log("Details are : ", details)
//   return (
//     <React.Fragment>
//       <BootstrapDialog
//         onClose={onClick}
//         aria-labelledby="customized-dialog-title"
//         open={open}
//       >
//         <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="dialog-title">
//           {title}
//         </DialogTitle>
//         <IconButton
//           aria-label="close"
//           onClick={onClick}
//           sx={{
//             position: 'absolute',
//             right: 8,
//             top: 8,
//             color: 'white',
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//         <DialogContent className='dialog-box'>
//           <Typography>
//             {/* <div className="dropdown-container">
//       <div className="dropdown-header" onClick={toggling}>
//         Your Rank 21
//         {isOpen ? <span><KeyboardArrowUpIcon/></span> : <span><KeyboardArrowDownIcon/></span>}
//       </div>
//       {isOpen && (
//         <ul className="dropdown-list">
//           {options.map(option => (
//             <li className="dropdown-list-item" onClick={onOptionClicked(option)} key={Math.random()}>
//               {option}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div> */}
//            <TableContainer component={Paper} className={classes.container}>
//             <Table stickyHeader className={classes.table} aria-label="sticky table">
//               <TableHead>
//                 <TableCell>Name</TableCell>
//                 <TableCell>Email</TableCell>
//                 <TableCell>UserType</TableCell>
//               </TableHead>
//               {details.map((i) => (
//                 <TableBody>
//                   <>
//                     <TableCell>{i.name}</TableCell>
//                     <TableCell>{i.id}</TableCell>
//                     <TableCell>{i.type}</TableCell>
//                   </>
//                 </TableBody>
//               ))}
//             </Table>
//             </TableContainer>
//           </Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={onClick} variant="contained">
//             Ok
//           </Button>
//         </DialogActions>
//       </BootstrapDialog>
//     </React.Fragment>
//   );
// }
