import React, { useEffect, useState } from 'react'
import LeaderboardUsericon from "../../../Images/LeaderboardUserIcon.png";
import RankTag from '../RankTag';
import {Divider} from '@material-ui/core'
function UserRank({details}) {
    // const fetchData = () => {
    //     fetch("https://prod.appstudioltts.com/api/topinvest/ContributorName/highCount2")
    //         .then((response) => response.json())
    //         .then((data) => {
    //             setTableDataContributors(data);
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching contributors data:", error);
    //         });
    // }

    // useEffect(()=>{
    //     fetchData();
    // },[])

    const sortedArray = details.sort((a,b)=>a.rank>b.rank);
    console.log('count rank :', sortedArray)


   

    return (
        <>
        { sortedArray.slice(0,4).map((item)=>{
             const name = item.name.toLowerCase()
             const userName = name
             .split(' ')
             .map(word => {
               return word.charAt(0).toUpperCase() + word.slice(1);
             })
             .join(' ');
            return(
                <div className='userdetails'>
                <img src={LeaderboardUsericon} width='39px' height='39px' className='usericon' />
                <span className='usertext'>
                    <span style={{ fontWeight: 'bold', whiteSpace:'nowrap',overflow:'hidden', textOverflow:'ellipsis', width:'340px' }}>{userName}</span>
                    <span  style={{ whiteSpace:'nowrap',overflow:'hidden', textOverflow:'ellipsis', width:'340px' }}>Consumed Components</span>
                </span>
               <div> <Divider/></div>
            </div>
            )
        })}
        </>
    )
}

export default UserRank;