import React, { useEffect, useState } from 'react'
import LeaderboardUsericon from "../../../Images/LeaderboardUserIcon.png";
import RankTag from '../RankTag';
import { Divider } from '@material-ui/core'
function UserRank({ details, val }) {
    const sortedArray = details.sort((a, b) => a.rank > b.rank);
    console.log('count rank :', sortedArray)

    const [name, setName] = useState('')
    return (
        <>
            {sortedArray.slice(0, 3).map((item) => {
                const name = item.senderName.toLowerCase()
                const userName = name
                    .split(' ')
                    .map(word => {
                        return word.charAt(0).toUpperCase() + word.slice(1);
                    })
                    .join(' ');
                return (
                    <div className='userdetails'>
                        <img src={LeaderboardUsericon} width='39px' height='39px' className='usericon' />
                        <span className='usertext'>
                            <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '300px' }}>{userName}</span>
                            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '300px' }}>Contributed Components</span>
                        </span>
                        <span style={{ position: 'relative', bottom: '19px', left: '170px' }}><RankTag rank={item.rank} /></span>
                        <span> <Divider /></span>
                    </div>
                )
            })}
        </>
    )
}

export default UserRank;