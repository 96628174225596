import "../App.css";
import ContributorPage from "./ContributorPage";
import HomePage from "./Homepage";
// import Contribute from "./Contribute";
// import Contribute from "./Contribute/Contribute";
import Contribute from './Contribute/Contributor'
import LoginPage from "./LoginPage";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";


function App() {
  return (
    <>
      <Routes>
        <Route path="/home" element={<HomePage />} />
        <Route path="/contributorPage" element={<ContributorPage />} />
        <Route path="/contribute" element={<Contribute />} />

        <Route path="/" element={<LoginPage />} />
      </Routes>
    </>
  );
}

export default App;
