// import React from 'react'
// function Dialog() {
//     const handleDropdownClick = () => {
//         setIsDropdownOpen(!isDropdownOpen);
//       };
//     return (
//         <>
//             <Dialog
//                 open={openDialog71_1}
//                 onClose={handleCloseDialog71_1}
//                 fullWidth
//                 maxWidth="sm"
//             >
//                 <DialogTitle className="dialogTitleStyle">
//                     <p> Leaderboard </p>
//                     <CloseButton aria-label="close" onClick={handleCloseDialog71_1}>
//                         <CloseIcon />
//                     </CloseButton>
//                 </DialogTitle>
//                 <DialogContent className="dialogContent">
//                     <img
//                         src={LeaderboardUsericon}
//                         alt="Leaderboard User Icon"
//                         className="user-icon"
//                         style={{
//                             height: "30px",
//                             width: "30px",
//                             marginTop: "10px",
//                             marginLeft: "387px"
//                         }}
//                     />
//                     <p style={{ marginTop: "-33px", marginLeft: "421px" }}> Your Rank
//                         <span className="rank-no1">1</span>
//                         <img
//                             src={dropdowniconL}
//                             alt="dropdownL"
//                             style={{ height: "10px", width: "18px", marginLeft: "5px" }}
//                             onClick={handleDropdownClick}
//                         />
//                     </p>
//                     {isDropdownOpen && (
//                         <ul
//                             style={{
//                                 listStyleType: "none",
//                                 padding: 0,
//                                 position: "absolute",
//                                 top: "95px", // adjust as needed
//                                 left: "438px", // adjust as needed
//                                 backgroundColor: "#fff",
//                                 border: "1px solid #ccc",
//                                 borderRadius: "4px",
//                                 padding: "4px",
//                             }}
//                         >
//                             <li
//                                 style={{
//                                     padding: "4px 2px",
//                                     cursor: "pointer",
//                                     backgroundColor: "transparent",
//                                     borderRadius: "4px",
//                                 }}
//                                 onMouseOver={(e) => (e.target.style.backgroundColor = "#add8e6")}
//                                 onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
//                                 onClick={handleDownloadCertificate}
//                             >
//                                 Download Certificate
//                             </li>
//                             <li
//                                 style={{
//                                     padding: "4px 3px",
//                                     cursor: "pointer",
//                                     backgroundColor: "transparent",
//                                     borderRadius: "4px",
//                                 }}
//                                 onMouseOver={(e) => (e.target.style.backgroundColor = "#add8e6")}
//                                 onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
//                                 onClick={() => console.log("More clicked")}
//                             >
//                                 More
//                             </li>
//                         </ul>
//                     )}
//                     {/* <div className="rectangle-container">
//             <div className="blue-rectangle2">
//               <img src={iconwithstar} style={{ height: "45px", width: "45px", position:"relative",  top: "-18px", left:"22px" }} alt="iconwithstar" />

//               <img src={icon2L} style={{ height: "25px", width: "25px",position: "absolute", bottom: "21px",  right: "27px"}} alt="LeaderboardIcon" />

//             </div>
//             <div className="blue-rectangle1">
//               <img src={iconwithstar} style={{ height: "70px", width: "70px", marginLeft: "25px", marginTop: "-30px" }} alt="iconwithstar" />
//               <img src={icon1L} style={{ height: "65px", width: "65px", marginTop: "24px", marginLeft: "28px" }} alt="Leaderboar Icon" />
//             </div>
//             <div className="blue-rectangle3">
//               <img src={iconwithstar} style={{ height: "45px", width: "45px", position:"relative",top:"-18px", left:"22px" }} alt="iconwithstar" />
//               <img src={icon3L} style={{ height: "25px", width: "25px", position:"absolute", bottom: "21px",  right: "27px"}} alt="Leader Icon" />
//             </div>
//           </div> */}

//                     <div className="rectangle-container">
//                         <div className="blue-rectangle2">
//                             <img src={iconwithstar} style={{ height: "45px", width: "45px", position: "relative", top: "-18px", left: "22px" }} alt="iconwithstar" />
//                             <img src={icon2L} style={{ height: "25px", width: "25px", position: "absolute", bottom: "21px", right: "27px" }} alt="LeaderboardIcon" />
//                             {details[1] && (
//                                 <p style={{ position: "relative", top: "-26px", left: "19px", color: "white", fontWeight: "bold" }}>
//                                     {details[1].ContributorName}
//                                 </p>
//                             )}
//                         </div>
//                         <div className="blue-rectangle1">
//                             <img src={iconwithstar} style={{ height: "70px", width: "70px", marginLeft: "25px", marginTop: "-30px" }} alt="iconwithstar" />
//                             <img src={icon1L} style={{ height: "65px", width: "65px", marginTop: "24px", marginLeft: "28px" }} alt="Leaderboar Icon" />
//                             {details[0] && (
//                                 <p style={{ position: "relative", top: "-111px", left: "32px", color: "white", fontWeight: "bold" }}>
//                                     {details[0].ContributorName}
//                                 </p>
//                             )}
//                         </div>
//                         <div className="blue-rectangle3">
//                             <img src={iconwithstar} style={{ height: "45px", width: "45px", position: "relative", top: "-18px", left: "22px" }} alt="iconwithstar" />
//                             <img src={icon3L} style={{ height: "25px", width: "25px", position: "absolute", bottom: "21px", right: "27px" }} alt="Leader Icon" />
//                             {details[2] && (
//                                 <p style={{ position: "relative", top: "-37px", left: "19px", color: "white", fontWeight: "bold" }}>
//                                     {details[2].ContributorName}
//                                 </p>
//                             )}
//                         </div>
//                     </div>


//                     <div style={{ maxHeight: '250px', overflow: 'auto' }}>
//                         <Table className="tableLeader leaderboard-data">
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell className="tableCellLeader" style={{ fontWeight: 'bold' }}>Rank</TableCell>
//                                     <TableCell className="tableCellLeader" style={{ fontWeight: 'bold' }} >Name</TableCell>
//                                     <TableCell className="tableCellLeader" style={{ fontWeight: 'bold' }} >Email Id</TableCell>

//                                     <TableCell className="tableCellLeader" align="right" style={{ fontWeight: 'bold' }}>Component Count</TableCell>

//                                 </TableRow>
//                             </TableHead>
//                             <TableBody >
//                                 {details.map((row) => (
//                                     <TableRow key={row.id}>
//                                         <TableCell className="tableCellLeader">
//                                             {row.rank}
//                                         </TableCell>
//                                         <TableCell className="tableCellLeader" >
//                                             {row.ContributorName}
//                                         </TableCell>
//                                         <TableCell className="tableCellLeader" >
//                                             {row.Email}
//                                         </TableCell>

//                                         <TableCell className="tableCellLeader" align="center">
//                                             {row.Contributed_Component_count}
//                                         </TableCell>

//                                     </TableRow>
//                                 ))}
//                             </TableBody>
//                         </Table>
//                     </div>

//                     <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
//                         <Button style={{ textTransform: 'unset' }}
//                             variant="contained"
//                             color="primary"
//                             onClick={handleCloseDialog71_1}
//                         >
//                             Ok
//                         </Button>
//                     </div>
//                 </DialogContent>
//             </Dialog>

//             <Dialog
//                 open={openDialog71_2}
//                 onClose={handleCloseDialog71_2}
//                 fullWidth
//                 maxWidth="sm"
//             >
//                 <DialogTitle className="dialogTitleStyle">
//                     <p className="PtagforPopupHeader">Total users</p>
//                     <CloseButton aria-label="close" onClick={handleCloseDialog71_2}>
//                         <CloseIcon />
//                     </CloseButton>
//                 </DialogTitle>
//                 <DialogContent className="dialogContent">
//                     <TableContainer component={Paper}>
//                         <Table>
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell>ID</TableCell>
//                                     <TableCell>Name</TableCell>
//                                     <TableCell>Type</TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody>
//                                 {tableData71_2.map((row) => (
//                                     <TableRow key={row.id}>
//                                         <TableCell>{row.id}</TableCell>
//                                         <TableCell>{row.name}</TableCell>
//                                         <TableCell>{row.type}</TableCell>
//                                     </TableRow>
//                                 ))}
//                             </TableBody>
//                         </Table>
//                     </TableContainer>
//                 </DialogContent>
//             </Dialog>

//             <Dialog
//                 open={openDialog7}
//                 onClose={handleCloseDialog7}
//                 fullWidth
//                 maxWidth="sm"
//             >
//                 <DialogTitle className="dialogTitleStyle">
//                     <p className="PtagforPopupHeader">Contributors</p>
//                     <CloseButton aria-label="close" onClick={handleCloseDialog7}>
//                         <CloseIcon />
//                     </CloseButton>
//                 </DialogTitle>
//                 <DialogContent className="dialogContent">
//                     <TableContainer component={Paper}>
//                         <Table>
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell>Name</TableCell>
//                                     <TableCell>ID</TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody>
//                                 {tableData7.map((row) => (
//                                     <TableRow key={row.id2}>
//                                         <TableCell>{row.id}</TableCell>
//                                         <TableCell>{row.name}</TableCell>
//                                     </TableRow>
//                                 ))}
//                             </TableBody>
//                         </Table>
//                     </TableContainer>
//                 </DialogContent>
//             </Dialog>


//             <Dialog
//                 open={openPopup50}
//                 onClose={handleClose50}
//                 fullWidth
//                 maxWidth="sm"
//             >
//                 <DialogTitle className="dialogTitleStyle">
//                     <p className="PtagforPopupHeader">Total Components</p>
//                     <CloseButton aria-label="close" onClick={handleClose50}>
//                         <CloseIcon />
//                     </CloseButton>
//                 </DialogTitle>
//                 <DialogContent className="dialogContent">
//                     <TableContainer component={Paper}>
//                         <Table>
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell>ID</TableCell>
//                                     <TableCell>Components</TableCell>
//                                     <TableCell>Technology</TableCell>
//                                     <TableCell>Contributor</TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody>
//                                 {tableData50.map((row) => (
//                                     <TableRow key={row.id}>
//                                         <TableCell>{row.ID}</TableCell>
//                                         <TableCell>{row.Components}</TableCell>
//                                         <TableCell>{row.Technology}</TableCell>
//                                         <TableCell>{row.Contributor}</TableCell>
//                                     </TableRow>
//                                 ))}
//                             </TableBody>
//                         </Table>
//                     </TableContainer>
//                 </DialogContent>
//             </Dialog>
//         </>
//     )
// }
// export default Dialog;




import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { TableBody, TableCell, Table, TableHead, TableRow, TableContainer, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LeaderboardUserIcon from '../../../Images/LeaderboardUserIcon.png';
import icon1L from "../../../Images/1icon.png";
import icon2L from "../../../Images/2icon.png";
import icon3L from "../../../Images/3icon.png";
import iconwithstar from "../../../Images/iconwithstar.png";
import dropdowniconL from "../../../Images/dropdownicon.png";
import Certificate from '../Certificate/Certificate';
import ReactToPrint from 'react-to-print';
import moment from 'moment-timezone';
import '../../../Styling/certificate.css';
import logo from '../../../Images/LttsLogo.png';
import appstudiologo from '../../../Images/Appstudiopng.png';
// import ComponentToPrint from './Certificate/CertificateContent';
import { useMsal } from "@azure/msal-react";
import '../../../Styling/leaderboard.css';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from "@mui/material/Tooltip";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles({
  table: {
    minWidth: 450,
    marginBottom: '7px'
  },
  container: {
    // maxHeight: 230,
    // height: 230
  },
  tbody: {
    fontSize: '13px'
  }
});

export default function CustomizedDialogs({ details,onClick }) {
  const { instance } = useMsal();
  const [open, setOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const options = ['Download Certificate', 'Place Holder', 'Place Holder'];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openDialog71_1, setOpenDialog71_1] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  const classes = useStyles();
  let componentRef = useRef();
  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCloseDialog71_1 = () => {
    setOpenDialog71_1(false);
  };
  const onOptionClicked = value => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false)
  };

  const sortedArray = details.sort((a, b) => a.rank > b.rank);
  let userRank;
  const loggedUser = instance.getAllAccounts()[0]?.username;
  // const loggedUser = localStorage.getItem('usermail')
  console.log("Logged in User is :",loggedUser)
  sortedArray.filter(item => item.email == loggedUser).map((user) => userRank = user.rank)
  console.log("Userrank is : ", sortedArray)
  
  //Custom Tooltip
  const CustomTooltip =({index})=>{
    return(
      <div>
          <p>User Email : { sortedArray[index].email}</p>  
         
          <p>Components Count : {sortedArray[index].publishedCount}</p>
      </div>
    )
  }

  return (
    <React.Fragment>
      <BootstrapDialog onClose={onClick} aria-labelledby="customized-dialog-title" open={open} >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="dialog-title">
          Leaderboard
        </DialogTitle>
        <IconButton aria-label="close" onClick={onClick} sx={{ position: 'absolute', right: 8, top: 8, color: 'white' }} >
          <CloseIcon />
        </IconButton>
        <DialogContent className="dialogContent">
          <img src={LeaderboardUserIcon} alt="Leaderboard User Icon"  className="user-icon" />
          <p className="yourrank"> Your Rank
            <span className="rank-no1">{userRank}</span>
            <img src={dropdowniconL} alt="dropdownL" style={{ height: "8px", width: "14px", marginLeft: "5px" }}
              onClick={handleDropdownClick} onKeyDown={handleDropdownClick} />
          </p>
          {isDropdownOpen && (
            <ul style={{listStyleType: "none", padding: 0, position: "absolute",
                top: "101px", // adjust as needed
                left: "438px", // adjust as needed
                backgroundColor: "#fff", border: "1px solid #ccc", borderRadius: "4px", fontSize: '14px' }}>
              <li
                style={{ padding: "4px 2px", cursor: "pointer", backgroundColor: "transparent", borderRadius: "4px" }}
                onMouseOver={(e) => (e.target.style.backgroundColor = "#add8e6")}
                onFocus={(e) => (e.target.style.backgroundColor = "#add8e6")}
                onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
                onBlur={(e) => (e.target.style.backgroundColor = "transparent")} >
                <Certificate list={sortedArray.filter(item => item.email == loggedUser)}/>
              </li>
              <li style={{ padding: "4px 3px", cursor: "pointer", backgroundColor: "transparent", borderRadius: "4px"}}
                onMouseOver={(e) => (e.target.style.backgroundColor = "#add8e6")}
                onFocus={(e) => (e.target.style.backgroundColor = "#add8e6")}
                onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
                onBlur={(e) => (e.target.style.backgroundColor = "transparent")}
                onClick={() => console.log("More clicked")}
                onKeyDown={() => console.log("More clicked")}>
                More
              </li>
            </ul>
          )}
          <div className="rectangle-container">
          <Tooltip title={<CustomTooltip index={1} />} classes={{ tooltip: classes.Mytooltip }} style={{cursor:"pointer"}}>
            <div className="blue-rectangle2">
              <img src={iconwithstar} style={{ height: "45px", width: "45px", position: "relative", top: "-18px", left: "28px" }} alt="iconwithstar" />
              <img src={icon2L} style={{ height: "25px", width: "25px", position: "absolute", bottom: "14px", right: "40px" }} alt="LeaderboardIcon" />
              {/* <span className='rankcard' >{sortedArray[1].rank}</span> */}

              {sortedArray[1] && (
                sortedArray[1].senderName.length<=18 ? 
                <p  style={{ position: "relative", top: "-26px", color: "white", fontWeight: "bold", textAlign: 'center',fontSize:'small' }}>
                  {sortedArray[1].senderName}
                </p> :
                <p  style={{ position: "relative", top: "-26px", color: "white", fontWeight: "bold", textAlign: 'center' ,whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px',left:'12px',fontSize:'small'}}>
                {sortedArray[1].senderName}
              </p>
              )}
            </div>
            </Tooltip>

            <Tooltip title={<CustomTooltip index={0}/>} classes={{ tooltip: classes.Mytooltip }} style={{cursor:"pointer"}}>
            <div className="blue-rectangle1">
              <img src={iconwithstar} style={{ height: "70px", width: "70px", marginLeft: "31px", marginTop: "-30px" }} alt="iconwithstar" />
              <img src={icon1L} style={{ height: "65px", width: "65px", marginTop: "24px", marginLeft: "28px" }} alt="Leaderboar Icon" />
              {/* <span className='rankcard' >{sortedArray[0].rank}</span> */}

              {sortedArray[0] && (
                sortedArray[0].senderName.length<=18 ? 
                <p  style={{ position: "relative",  bottom:"72%", color: "white", fontWeight: "bold", textAlign: 'center',fontSize:'small' }}>
                  {sortedArray[0].senderName}
                </p> :
                <p  style={{ position: "relative", bottom:"72%", color: "white", fontWeight: "bold", textAlign: 'center',fontSize:'small' ,whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px',left:'24px'}}>
                {sortedArray[0].senderName}
              </p>
              )}
            </div>
            </Tooltip>

            <Tooltip title={<CustomTooltip index={2}/>} classes={{ tooltip: classes.Mytooltip }} style={{cursor:"pointer"}}>
            <div className="blue-rectangle3">
              <img src={iconwithstar} style={{ height: "45px", width: "45px", position: "relative", top: "-18px", left: "28px" }} alt="iconwithstar" />
              <img src={icon3L} style={{ height: "25px", width: "25px", position: "absolute", bottom: "14px", right: "40px" }} alt="Leader Icon" />
              {/* <span className='rankcard' >{sortedArray[2].rank}</span> */}

              {sortedArray[2] && (
               sortedArray[2].senderName.length<=18 ? 
               <p  style={{ position: "relative", top: "-26px", color: "white", fontWeight: "bold", textAlign: 'center',fontSize:'small' }}>
                 {sortedArray[2].senderName}
               </p> :
               <p  style={{ position: "relative", top: "-26px", color: "white", fontWeight: "bold", textAlign: 'center',fontSize:'small' ,whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px',left:'12px'}}>
               {sortedArray[2].senderName}
             </p>
              )}
            </div>
            </Tooltip>
          </div>


          <TableContainer component={Paper} className={classes.container}>
            <Table stickyHeader className={classes.table} aria-label="sticky table">
              <TableHead style={{ display: 'block', maxHeight: 165, overflow: 'auto'}}>
                <TableCell style={{width:"7%"}}>Rank</TableCell>
                <TableCell style={{width:"20%"}}>Name</TableCell>
                <TableCell style={{width:"20%"}}>Email</TableCell>
                <TableCell style={{width:"20%"}}>Components Count</TableCell>
              </TableHead>
              <TableBody className={classes.tbody} style={{ display: 'block', maxHeight: 165, overflow: 'auto'}}>
                {sortedArray.slice(3,).map((i) => (
                  <TableRow >
                    <TableCell style={{width:"7%"}}>{i.rank}</TableCell>
                    <TableCell style={{width:"20%"}}>{i.senderName}</TableCell>
                    <TableCell style={{width:"20%", paddingLeft:"7px"}} className="text-break">{i.email}</TableCell>
                    <TableCell style={{width:"20%"}}>{i.publishedCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClick} variant="contained">
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}